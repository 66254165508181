import { useTranslation } from 'next-i18next';

import { ProductCard } from '@boss/ui';

import { useFavorites, usePrice, useProductAccountPrice, useProductMap, useProfile } from '../../hooks';

const ProductCardWrapper = ({
  price,
  hideWishlistAction,
  showCounter,
  ...props
}: React.ComponentProps<typeof ProductCard> & { hideWishlistAction?: boolean; showCounter?: boolean }) => {
  const { t } = useTranslation('common');
  const { favorites, toggleFavorite } = useFavorites();
  const { showPrice } = usePrice();
  const {
    priceToShow,
    fromPriceUnit,
    strikePrice,
    isLoading: priceInfoLoading,
    productLabels,
  } = useProductAccountPrice(props.id, price);

  const { mapProductLabels } = useProductMap();
  const { isLoggedIn } = useProfile();

  const labels =
    props.labels ?? productLabels ? [...mapProductLabels(productLabels ?? []), ...(props.labels ?? [])] : undefined;

  return (
    <ProductCard
      {...props}
      handleAddToWishlist={hideWishlistAction ? undefined : () => toggleFavorite('PRODUCTS', props.id)}
      inWishlist={favorites['PRODUCTS']?.includes(props.id)}
      labels={labels}
      price={showPrice ? priceToShow : undefined}
      priceIsLoading={isLoggedIn ? priceInfoLoading : false}
      showCounter={showCounter}
      strikePrice={strikePrice}
      translations={{
        quantityPrefix: props.translations?.quantityPrefix ?? t('quantityPrefix'),
        disclaimer: props.translations?.disclaimer ?? t('fromPriceDisclaimer'),
        unitPrice: fromPriceUnit ? t(`fromPriceUnits.${fromPriceUnit}`) ?? '' : '',
        unitLabel: fromPriceUnit ? t(`fromPriceUnitsShort.${fromPriceUnit}`) ?? '' : '',
      }}
    />
  );
};

export default ProductCardWrapper;
