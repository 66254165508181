import { BasicVariant } from '@boss/types/b2b-b2c';
import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Image, Link } from '..';

type Props = {
  image: string;
  variant?: BasicVariant;
  colors?: ReactNode;
  className?: string;
  url?: string;
  actions?: ReactNode;
};

const CardStyles = cva('bg-white shadow-lg overflow-hidden my-3 h-full', {
  variants: {
    variant: {
      primary: 'rounded-t-modal rounded-r-modal',
      secondary: '',
    },
  },
});

const InspirationCard = ({ image, variant = 'primary', colors, className, url, actions }: Props) => {
  return (
    <div className={twMerge(CardStyles({ variant }), className)}>
      <Link href={url}>
        <Image className="aspect-[25/18]" src={image} />
      </Link>
      <div className="flex gap-4 px-4 py-2">
        <div className="flex w-full gap-2">
          <div className="min-h-15 grid w-full grid-cols-5 gap-4">{colors}</div>
        </div>
      </div>
      {actions && <div className="pb-4">{actions}</div>}
    </div>
  );
};

export default InspirationCard;
