import { useTranslation } from 'next-i18next';
import { ComponentProps, useEffect, useState } from 'react';

import { IProduct, IStore } from '@boss/services';
import { Color, ColorGroup, IArticle, ISearchProduct } from '@boss/types/b2b-b2c';
import { calcAmountOfpaintForSurface } from '@boss/utils';

import { PaintGuideResultCard, SurfaceCalculatorModal } from '../../components';

type Product = IProduct | ISearchProduct;

type Props = {
  aftertreatmentProduct?: Product | null | undefined;
  finishProduct?: Product | null | undefined;
  pretreatmentProduct?: Product | null | undefined;
  supportProduct: Product | null | undefined;
  wallpaperApplicationProduct?: Product | null | undefined;
  withCounter?: boolean;
  preCalculatedSurface?: number;
  store: IStore | undefined;
  colorGroups: ColorGroup[] | null;
  selectedColor?: Color | null;
  showBaseColors?: boolean;
  selectedColorBase?: string;
};

type ResultProps = {
  counter: number;
  product: Product;
  subtitle: string;
  store: IStore | undefined;
  totalPaintNeeded: number;
  onCalculateSurface?: (articles: IArticle[]) => void;
  withCounter?: boolean;
  colorGroups: ColorGroup[] | null;
  selectedColor?: Color | null;
  showBaseColors?: boolean;
  selectedColorBase?: string;
};

const Result = ({
  withCounter = false,
  totalPaintNeeded,
  counter,
  product,
  subtitle,
  store,
  onCalculateSurface,
  colorGroups,
  selectedColor,
  showBaseColors = false,
  selectedColorBase,
}: ResultProps) => (
  <div className="xl:gap-x-30 my-10 lg:flex">
    <div className="lg:min-w-48 mb-5 flex items-baseline md:flex-col">
      {withCounter && <h2 className="lg:text-40 mb-3 mr-3 text-xl">{counter}.</h2>}
      <h4>{subtitle}</h4>
    </div>
    <PaintGuideResultCard
      amountOfPaint={totalPaintNeeded}
      colorGroups={colorGroups}
      onCalculateSurface={onCalculateSurface}
      preChosenColor={selectedColor}
      preChosenColorBase={selectedColorBase}
      product={product}
      selectedStore={store}
      showBaseColors={showBaseColors}
    />
  </div>
);

const PaintguideResultsWrapper = ({
  preCalculatedSurface = 0,
  finishProduct,
  supportProduct,
  pretreatmentProduct,
  wallpaperApplicationProduct,
  aftertreatmentProduct,
  ...props
}: Props) => {
  const { t } = useTranslation('paintguide');

  const [totalPaintNeeded, setTotalPaintNeeded] = useState(0);
  const [showSurfaceCalculatorModal, setShowSurfaceCalculatorModal] = useState(false);
  const [calculated, setCalculated] = useState<ComponentProps<typeof SurfaceCalculatorModal>['calculated']>();
  const [articles, setArticles] = useState<IArticle[]>();

  useEffect(() => {
    if (preCalculatedSurface && finishProduct?.consumptionOptions) {
      const activeConsumption = finishProduct.consumptionOptions[0].usage['average'];
      const totalPaintNeeded = calcAmountOfpaintForSurface(activeConsumption, preCalculatedSurface);

      setTotalPaintNeeded(totalPaintNeeded);
    }
  }, [preCalculatedSurface, finishProduct?.consumptionOptions]);

  const getCounter = (productType: 'pretreatment' | 'wallpaper' | 'support' | 'finish' | 'aftertreatment') => {
    let counter = 0;

    if (pretreatmentProduct) {
      counter++;
    }
    if (productType === 'pretreatment') {
      return counter;
    }

    if (wallpaperApplicationProduct) {
      counter++;
    }
    if (productType === 'wallpaper') {
      return counter;
    }

    if (supportProduct) {
      counter++;
    }
    if (productType === 'support') {
      return counter;
    }

    if (finishProduct) {
      counter++;
    }
    if (productType === 'finish') {
      return counter;
    }

    if (aftertreatmentProduct) {
      counter++;
    }
    if (productType === 'aftertreatment') {
      return counter;
    }

    return counter;
  };

  return (
    <>
      {pretreatmentProduct && (
        <Result
          {...props}
          counter={getCounter('pretreatment')}
          product={pretreatmentProduct}
          subtitle={t('pretreatment')}
          totalPaintNeeded={totalPaintNeeded}
        />
      )}
      {wallpaperApplicationProduct && (
        <Result
          {...props}
          counter={getCounter('wallpaper')}
          product={wallpaperApplicationProduct}
          subtitle={t('wallpaperApplication')}
          totalPaintNeeded={totalPaintNeeded}
        />
      )}
      {supportProduct && (
        <Result
          {...props}
          counter={getCounter('support')}
          onCalculateSurface={articles => {
            setShowSurfaceCalculatorModal(true);
            setArticles(articles);
          }}
          product={supportProduct}
          subtitle={t('supportLayer')}
          totalPaintNeeded={totalPaintNeeded}
        />
      )}
      {finishProduct && (
        <Result
          counter={getCounter('finish')}
          onCalculateSurface={articles => {
            setShowSurfaceCalculatorModal(true);
            setArticles(articles);
          }}
          product={finishProduct}
          subtitle={t('finshedLayer')}
          totalPaintNeeded={totalPaintNeeded}
          {...props}
        />
      )}
      {aftertreatmentProduct && (
        <Result
          {...props}
          counter={getCounter('aftertreatment')}
          product={aftertreatmentProduct}
          subtitle={t('aftertreatment')}
          totalPaintNeeded={totalPaintNeeded}
        />
      )}
      {showSurfaceCalculatorModal && (
        <SurfaceCalculatorModal
          articles={articles}
          calculated={calculated}
          consumptionOptions={finishProduct?.consumptionOptions ?? supportProduct?.consumptionOptions ?? []}
          onClose={() => setShowSurfaceCalculatorModal(false)}
          onConfirm={calc => {
            setTotalPaintNeeded(calc.totalPaintNeeded);
            setCalculated(calc);
          }}
          preCalculated={preCalculatedSurface}
          product={supportProduct}
        />
      )}
    </>
  );
};

export default PaintguideResultsWrapper;
