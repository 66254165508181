/* eslint-disable */
// @ts-nocheck
import { useState } from 'react';

const Register = () => {
  const [activeStep, setActiveStep] = useState(1);

  return (
    <>
      {activeStep === 1 && (
        <>
          <div className="heading">
            <h1 role="heading">Gebruikersgegevens</h1>
          </div>
          <div className="buttons" />
          <div className="intro">
            <p>Geef de volgende gegevens op.</p>
          </div>
          <form id="attributeVerification" action="JavaScript:void(0);">
            <div id="requiredFieldMissing" className="error pageLevel" style={{ display: 'none' }} aria-hidden="true">
              Er ontbreekt een vereist veld. Vul alle vereiste velden in en probeer het opnieuw.
            </div>
            <div id="fieldIncorrect" className="error pageLevel" style={{ display: 'none' }} aria-hidden="true">
              Een of meer velden zijn onjuist ingevuld. Controleer deze items en probeer het opnieuw.
            </div>
            <div
              id="claimVerificationServerError"
              className="error pageLevel"
              style={{ display: 'none' }}
              aria-hidden="true"
            />
            <div id="attributeList" className="attr">
              <ul>
                <li className="VerificationControl emailVerificationControl_li">
                  <div className="attrEntry">
                    <label id="emailVerificationControl_label" htmlFor="emailVerificationControl" />
                    <div className="error itemLevel" role="alert" />
                    <div className="verificationControlContent" id="emailVerificationControl">
                      <div className="verificationInfoText">
                        <div
                          id="emailVerificationControl_info_message"
                          aria-hidden="true"
                          role="alert"
                          aria-label="Verification is necessary. Please click Send button."
                          style={{ display: 'none' }}
                        >
                          Verification is necessary. Please click Send button.
                        </div>
                      </div>
                      <div className="verificationSuccessText">
                        <div
                          id="emailVerificationControl_success_message"
                          aria-hidden="true"
                          role="alert"
                          aria-label=""
                          style={{ display: 'none' }}
                        />
                      </div>
                      <div className="verificationErrorText error">
                        <div
                          id="emailVerificationControl_error_message"
                          aria-hidden="true"
                          role="alert"
                          aria-label=""
                          style={{ display: 'none' }}
                        />
                      </div>
                      <ul>
                        <li className="TextBox email_li email" aria-hidden="false" style={{ display: 'inline' }}>
                          <div className="attrEntry">
                            <label id="email_label" htmlFor="email">
                              E-mailadres*
                            </label>
                            <div className="error itemLevel" role="alert" />
                            <input
                              id="email"
                              className="textInput"
                              type="text"
                              placeholder="E-mailadres"
                              title="Email address that can be used to contact you."
                              aria-required="true"
                              autoFocus
                            />
                            <a
                              className="helpLink tiny"
                              href="javascript:void(0)"
                              data-help="Email address that can be used to contact you."
                            >
                              Wat is dit?
                            </a>
                          </div>
                        </li>
                        <li
                          className="TextBox VerificationCode_li VerificationCode"
                          aria-hidden="true"
                          style={{ display: 'none' }}
                        >
                          <div className="attrEntry">
                            <label id="VerificationCode_label" htmlFor="VerificationCode">
                              Verificatiecode*
                            </label>
                            <div className="error itemLevel" role="alert" />
                            <input
                              id="VerificationCode"
                              className="textInput"
                              type="text"
                              placeholder="Verificatiecode"
                              title="Enter your email verification code"
                              aria-required="true"
                              autoComplete="off"
                              disabled
                            />
                            <a
                              className="helpLink tiny"
                              href="javascript:void(0)"
                              data-help="Enter your email verification code"
                            >
                              Wat is dit?
                            </a>
                          </div>
                        </li>
                      </ul>
                      <div className="working" style={{ display: 'none' }} aria-hidden="true" />
                      <div className="buttons">
                        <button
                          className="sendCode"
                          id="emailVerificationControl_but_send_code"
                          type="button"
                          aria-label="Verificatiecode verzenden"
                          aria-hidden="false"
                          style={{ display: 'inline' }}
                        >
                          Verificatiecode verzenden
                        </button>
                        <button
                          className="verifyCode"
                          id="emailVerificationControl_but_verify_code"
                          type="button"
                          aria-label="Code verifiëren"
                          aria-hidden="true"
                          style={{ display: 'none' }}
                        >
                          Code verifiëren
                        </button>
                        <button
                          className="sendNewCode"
                          id="emailVerificationControl_but_send_new_code"
                          type="button"
                          aria-label="Nieuwe code verzenden"
                          aria-hidden="true"
                          style={{ display: 'none' }}
                        >
                          Nieuwe code verzenden
                        </button>
                        <button
                          className="changeClaims"
                          id="emailVerificationControl_but_change_claims"
                          type="button"
                          aria-label="E-mailadres aanpassen"
                          aria-hidden="true"
                          style={{ display: 'none' }}
                        >
                          E-mailadres aanpassen
                        </button>
                      </div>
                    </div>
                    <a className="helpLink tiny" href="javascript:void(0)">
                      Wat is dit?
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="buttons">
              <button
                id="continue"
                type="submit"
                form="attributeVerification"
                aria-disabled="true"
                aria-label="Doorgaan"
                tabIndex={-1}
                onClick={() => setActiveStep(2)}
              >
                Doorgaan
              </button>
              <button id="cancel" aria-label="Annuleren" formNoValidate>
                Annuleren
              </button>
            </div>

            <div className="verifying-modal" aria-live="assertive" aria-labelledby="verifying_blurb">
              <div id="verifying_blurb" />
            </div>
          </form>
        </>
      )}
      {activeStep === 2 && (
        <>
          <div className="heading">
            <h1 role="heading">Gebruikersgegevens</h1>
          </div>
          <div className="buttons"></div>
          <div className="intro">
            <p>Geef de volgende gegevens op.</p>
          </div>
          <form id="attributeVerification" action="JavaScript:void(0);">
            <div id="requiredFieldMissing" className="error pageLevel" style={{ display: 'none' }} aria-hidden="true">
              Er ontbreekt een vereist veld. Vul alle vereiste velden in en probeer het opnieuw.
            </div>
            <div id="fieldIncorrect" className="error pageLevel" style={{ display: 'none' }} aria-hidden="true">
              Een of meer velden zijn onjuist ingevuld. Controleer deze items en probeer het opnieuw.
            </div>
            <div
              id="claimVerificationServerError"
              className="error pageLevel"
              style={{ display: 'none' }}
              aria-hidden="true"
            ></div>
            <div id="attributeList" className="attr">
              <ul>
                <li className="Readonly readonlyEmail_li">
                  <div className="attrEntry">
                    <label id="readonlyEmail_label" htmlFor="readonlyEmail">
                      E-mailadres*
                    </label>
                    <div className="error itemLevel" role="alert"></div>
                    <input
                      id="readonlyEmail"
                      className="textInput"
                      type="text"
                      placeholder="E-mailadres"
                      value="jason.marysael@two-point-o.be"
                      disabled
                      readOnly
                      autoFocus
                    />
                    <div
                      data-lastpass-icon-root=""
                      style={{
                        position: 'relative !important',
                        height: '0px !important',
                        width: '0px !important',
                        float: 'left !important',
                      }}
                    ></div>
                    <a className="helpLink tiny" href="javascript:void(0)">
                      Wat is dit?
                    </a>
                  </div>
                </li>
                <li className="TextBox givenName_li">
                  <div className="attrEntry">
                    <label id="givenName_label" htmlFor="givenName">
                      Voornaam*
                    </label>
                    <div className="error itemLevel" role="alert"></div>
                    <input
                      id="givenName"
                      className="textInput"
                      type="text"
                      placeholder="Voornaam"
                      title="Your given name (also known as first name)."
                      aria-required="true"
                    />
                    <a
                      className="helpLink tiny"
                      href="javascript:void(0)"
                      data-help="Your given name (also known as first name)."
                    >
                      Wat is dit?
                    </a>
                  </div>
                </li>
                <li className="TextBox surname_li">
                  <div className="attrEntry">
                    <label id="surname_label" htmlFor="surname">
                      Achternaam*
                    </label>
                    <div className="error itemLevel" role="alert"></div>
                    <input
                      id="surname"
                      className="textInput"
                      type="text"
                      placeholder="Achternaam"
                      title="Your surname (also known as family name or last name)."
                      aria-required="true"
                    />
                    <a
                      className="helpLink tiny"
                      href="javascript:void(0)"
                      data-help="Your surname (also known as family name or last name)."
                    >
                      Wat is dit?
                    </a>
                  </div>
                </li>
                <li className="Password newPassword_li">
                  <div className="attrEntry">
                    <label id="newPassword_label" htmlFor="newPassword">
                      Nieuw wachtwoord*
                    </label>
                    <div className="error itemLevel" role="alert"></div>
                    <input
                      id="newPassword"
                      className="textInput"
                      type="password"
                      placeholder="Nieuw wachtwoord"
                      title="De minimale wachtwoordlengte is 8 karakters, gebruik kleine letters, hoofdletters, cijfers en speciale tekens."
                      autoComplete="new-password"
                      aria-required="true"
                    />
                    <div
                      data-lastpass-icon-root=""
                      style={{
                        position: 'relative !important',
                        height: '0px !important',
                        width: '0px !important',
                        float: 'left !important',
                      }}
                    ></div>
                    <a
                      className="helpLink tiny"
                      href="javascript:void(0)"
                      data-help="De minimale wachtwoordlengte is 8 karakters, gebruik kleine letters, hoofdletters, cijfers en speciale tekens."
                    >
                      Wat is dit?
                    </a>
                  </div>
                </li>
                <li className="Password reenterPassword_li">
                  <div className="attrEntry">
                    <label id="reenterPassword_label" htmlFor="reenterPassword">
                      Nieuw wachtwoord bevestigen*
                    </label>
                    <div className="error itemLevel" role="alert"></div>
                    <input
                      id="reenterPassword"
                      className="textInput"
                      type="password"
                      placeholder="Nieuw wachtwoord bevestigen"
                      title="Confirm new password"
                      autoComplete="new-password"
                      aria-required="true"
                    />
                    <div
                      data-lastpass-icon-root=""
                      style={{
                        position: 'relative !important',
                        height: '0px !important',
                        width: '0px !important',
                        float: 'left !important',
                      }}
                    ></div>
                    <a className="helpLink tiny" href="javascript:void(0)" data-help="Confirm new password">
                      Wat is dit?
                    </a>
                  </div>
                </li>
                <li className="TextBox extension_VatNumber_li">
                  <div className="attrEntry">
                    <label id="extension_VatNumber_label" htmlFor="extension_VatNumber">
                      BTW-nummer*
                    </label>
                    <div className="error itemLevel" role="alert"></div>
                    <input id="extension_VatNumber" className="textInput" type="text" placeholder="BTW-nummer" />
                    <a className="helpLink tiny" href="javascript:void(0)">
                      Wat is dit?
                    </a>
                  </div>
                </li>
                <li className="CheckboxMultiSelect extension_TermsAndConditions_li">
                  <div className="attrEntry">
                    <fieldset>
                      <legend id="extension_TermsAndConditions_label" htmlFor="extension_TermsAndConditions"></legend>
                      <div className="error itemLevel" role="alert"></div>
                      <div id="group_placeholder" hidden>
                        group
                      </div>
                      <input
                        id="extension_TermsAndConditions_AgreeToTermsOfUseConsentYes"
                        name="extension_TermsAndConditions"
                        type="checkbox"
                        aria-labelledby="extension_TermsAndConditions_label group_placeholder extension_TermsAndConditions_AgreeToTermsOfUseConsentYes"
                        value="AgreeToTermsOfUseConsentYes"
                      />
                      <label
                        id="AgreeToTermsOfUseConsentYes_option"
                        htmlFor="extension_TermsAndConditions_AgreeToTermsOfUseConsentYes"
                      >
                        Ik ga akkoord met de algemene voorwaarden
                      </label>
                    </fieldset>
                    <a className="helpLink tiny" href="javascript:void(0)">
                      Wat is dit?
                    </a>
                  </div>
                </li>
                <li className="CheckboxMultiSelect extension_SubscribeToNewsletter_li">
                  <div className="attrEntry">
                    <fieldset>
                      <legend
                        id="extension_SubscribeToNewsletter_label"
                        htmlFor="extension_SubscribeToNewsletter"
                      ></legend>
                      <div className="error itemLevel" role="alert"></div>
                      <div id="group_placeholder" hidden>
                        group
                      </div>
                      <input
                        id="extension_SubscribeToNewsletter_SubscribeToNewsletter"
                        name="extension_SubscribeToNewsletter"
                        type="checkbox"
                        aria-labelledby="extension_SubscribeToNewsletter_label group_placeholder extension_SubscribeToNewsletter_SubscribeToNewsletter"
                        value="SubscribeToNewsletter"
                      />
                      <label
                        id="SubscribeToNewsletter_option"
                        htmlFor="extension_SubscribeToNewsletter_SubscribeToNewsletter"
                      >
                        Schrijf je in op de nieuwsbrief
                      </label>
                    </fieldset>
                    <a className="helpLink tiny" href="javascript:void(0)">
                      Wat is dit?
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="buttons">
              <button
                id="continue"
                type="submit"
                form="attributeVerification"
                aria-disabled="false"
                aria-label="Doorgaan"
              >
                Doorgaan
              </button>
              <button id="cancel" aria-label="Annuleren" formNoValidate onClick={() => setActiveStep(1)}>
                Annuleren
              </button>
            </div>

            <div className="verifying-modal" aria-live="assertive" aria-labelledby="verifying_blurb">
              <div id="verifying_blurb"></div>
            </div>
          </form>
          <div className="disclaimer-container disclaimer-container-page-level">
            <a
              className="disclaimer-link"
              href="https://colora-acceptance.vercel.app/nl/algemene-verkoopsvoorwaarden"
              aria-label="Algemene voorwaarden"
            >
              Algemene voorwaarden
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default Register;
