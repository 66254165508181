import { THEME_CLASSNAMES } from '@boss/constants/corporate-jobs';
import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

import QuoteIcon from './QuoteIcon';
import { QuoteInstanceProps } from '../';
import Image from '../../Image';
const QuoteSecondary = ({
  quote,
  author,
  company,
  className,
  image,
  imagePosition,
  themeColor,
  background,
}: QuoteInstanceProps) => {
  const hasImageRight = image && imagePosition === 'right';
  const hasImageLeft = image && (imagePosition === 'left' || imagePosition === undefined);

  const backgroundClassName = background ? 'bg-white' : themeColor ? THEME_CLASSNAMES.background[themeColor] : '';
  const illustrationClassName = background ? 'text-white' : themeColor ? THEME_CLASSNAMES.text[themeColor] : '';
  const textClassname = background ? 'text-white' : '';

  return (
    <div
      aria-label="quote"
      className={twMerge(
        'grid w-full grid-cols-1 py-10',
        image ? 'gap-8 md:w-full md:grid-cols-2' : 'md:w-[70%]',
        className,
      )}
    >
      <div className="flex flex-col gap-3 md:gap-10">
        <QuoteIcon className={twMerge('text-green-sheen h-[60px] w-[60px]', illustrationClassName)} />
        <h6 className={twMerge('md:text-2.5xl text-2xl font-extralight', textClassname)}>{quote}</h6>
        {author && (
          <div className={twMerge('flex flex-row gap-6', textClassname)}>
            <div
              className={twMerge(
                'h-0.25 bg-green-sheen z-10 mb-2 w-full self-end',
                hasImageLeft ? 'md:-ml-20' : '',
                hasImageRight ? 'md:order-last md:-mr-20' : '',
                backgroundClassName,
              )}
            />
            <div>
              <div className="author text-end md:text-start">{author}</div>
              {company && <div className="function whitespace-nowrap text-end font-thin md:text-start">{company}</div>}
            </div>
          </div>
        )}
      </div>
      {image && (
        <div
          className={twMerge(
            'relative order-first aspect-video w-full md:-mb-4 md:h-auto',
            hasImageRight ? 'md:order-last' : '',
          )}
        >
          <Image alt={image.image?.title} className="absolute" src={image.image?.file.url} />
        </div>
      )}
    </div>
  );
};

export default QuoteSecondary;

<FontAwesomeIcon
  className={twMerge(
    'text-green-sheen h-[60px] w-[60px]',
    //illustrationClassName
  )}
  icon={faQuoteLeft}
  size="3x"
/>;
