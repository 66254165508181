import { FormikValues, useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { ComponentProps, FormEvent, forwardRef, useEffect, useImperativeHandle } from 'react';
import { twMerge } from 'tailwind-merge';

import { useRouter } from '@boss/hooks';
import { Alert, Button, Presence } from '@boss/ui';

import { FormFieldMapper } from '../../components';
import { useForm } from '../../hooks';
import { alignCountrySpecificFields } from '../../utils';
import { FormField } from '../Mappers/FormFieldMapper';

export type FormColumn = {
  id: string;
  title?: string;
  className?: string;
  titleClassName?: string;
  formFieldsWrapperClassName?: string;
  fields: FormField[];
};

export type FormValues = Record<string, string | number | boolean | { count: number; items: string[] }>;

/**
 * Props for the DynamicForm component.
 */
interface DynamicFormProps {
  id?: string;

  className?: string;

  buttonProps?: Partial<ComponentProps<typeof Button>>;

  /**
   * An array of fields to be included in the form.
   */
  fields?: FormField[];

  /**
   * An array of form columns, used to render multiple columns of form fields
   */
  columns?: FormColumn[];

  /**
   * ClassName property to extend the wrapping fields div, has a default styling of "grid gap-5 md:grid-cols-6"
   */
  formFieldsWrapperClassName?: string;

  /**
   * ClassName property to style the div that wraps the columns array
   */
  columnsWrapperClassName?: string;

  /**
   * Whether the form should be rendered in light mode.
   */
  variant?: 'light' | 'dark' | 'transparent';

  /**
   * A function to handle form submission.
   * @param {Record<string, string>} values - The form field values.
   */
  onSubmit?: (values: FormValues) => void;

  /**
   * The form title.
   */
  title?: string;

  /**
   * Action to be performed when the form values change
   */
  onFormValuesChange?: (formik: ReturnType<typeof useFormik>) => void;

  /**
   * Boolean to dictate the button's visiblity. Default = true.
   */
  showSubmitButton?: boolean;

  /**
   * Is the form being submitted?
   */
  isSubmitting?: boolean;

  /**
   * Submitting label for when the form is being submitted
   */
  submitLabel?: string;

  /**
   * Is the form submitted successfully?
   */
  isSuccess?: boolean;

  /**
   * What has to happen when we want to close the Alert?
   */
  onCloseAlert?: () => void;

  /**
   * Config options that overwrite the default useFormik parameters
   */
  options?: FormikValues;

  /**
   * Does the form have an error?
   */
  isError?: boolean;
  errorTitle?: string;
  errorSubtitle?: string;
  validateForm?: boolean[];
  disclaimer?: string | null;
  eventId?: string;
  shouldShowField?: (fieldValidateName: string) => boolean;
  shouldShowColumn?: (columnId: string) => boolean;
}

/**
 * A dynamic form component that renders fields based on the provided field names and handles form submission.
 * @param {DynamicFormProps} props - The component props.
 */

const DynamicForm = forwardRef(
  (
    {
      buttonProps,
      className,
      fields: initialFields,
      columns: initialColumns,
      formFieldsWrapperClassName = 'grid gap-5 md:grid-cols-6',
      columnsWrapperClassName,
      id,
      isSubmitting,
      isSuccess,
      onCloseAlert,
      onFormValuesChange,
      onSubmit,
      showSubmitButton = true,
      submitLabel,
      title,
      isError,
      errorTitle,
      errorSubtitle,
      variant,
      options,
      disclaimer,
      shouldShowField = () => true,
      shouldShowColumn = () => true,
    }: DynamicFormProps,
    ref,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const { locale } = useRouter();
    const { t } = useTranslation('forms');
    const { generateValidationSchema, getInitialValues, verifyRecaptcha } = useForm();
    const fieldsColumn = initialFields?.length ? [{ id: id ?? title ?? 'dynamic-form', fields: initialFields }] : [];
    const columns: FormColumn[] = alignCountrySpecificFields(locale, initialColumns ?? fieldsColumn);
    const fields = columns?.map(column => column.fields).flat() ?? [];

    const getButtonLabel = () => {
      if (isSubmitting) {
        return submitLabel ?? t('buttons.submitting');
      }

      return buttonProps?.label ?? t('buttons.submit');
    };

    const handleClose = () => {
      formik.resetForm();
      formik.setValues(getInitialValues(fields));
      formik.setTouched({});
      formik.setErrors({});
      onCloseAlert?.();
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const isValid = await verifyRecaptcha();

      if (isValid) {
        formik.handleSubmit(e);
      }
    };

    /**
     * Formik hook to manage form state and submission.
     */

    const validationSchema = generateValidationSchema(fields);

    const formik = useFormik<FormValues>({
      initialValues: getInitialValues(fields),
      validationSchema: validationSchema,
      onSubmit: onSubmit ?? (() => '/'),
      enableReinitialize: true,
      ...options,
    });

    useImperativeHandle(ref, () => ({
      validateAndSubmitForm: async () => {
        const errors = await formik.validateForm();

        formik.submitForm();

        return {
          errors: errors,
          values: formik.values,
        };
      },
    }));

    useEffect(() => {
      if (onFormValuesChange && formik.touched) {
        onFormValuesChange(formik);
      }
    }, [formik.values, formik, onFormValuesChange]);

    if (isSuccess) {
      return (
        <Presence className="grid md:grid-cols-2" id={`${id}-success-message-presence`} visible>
          <Alert iconSize="xl" onClose={handleClose} title={t('success.title') ?? ''} type="confirm">
            {t('success.subtitle')}
          </Alert>
        </Presence>
      );
    }

    return (
      <Presence className={twMerge('flex flex-col gap-6', className)} id={`${id}-dynamic-form-presence`} visible>
        {title && <h2>{title}</h2>}

        {disclaimer && <Alert type="info">{disclaimer}</Alert>}

        <form className="flex flex-col gap-5" id={id} onSubmit={handleSubmit}>
          {!!columns?.length && (
            <div className={columnsWrapperClassName}>
              {columns.map(column =>
                shouldShowColumn(column.id) ? (
                  <div className={column.className} key={column.id}>
                    {column.title && <h3 className={column.titleClassName}>{column.title}</h3>}
                    <div className={column.formFieldsWrapperClassName ?? formFieldsWrapperClassName}>
                      {column.fields.map(field =>
                        shouldShowField(field.validateName ?? field.name) ? (
                          <FormFieldMapper
                            className={field.colStyle ?? 'md:col-span-3'}
                            field={field}
                            formId={id}
                            formik={formik}
                            key={field.name}
                            variant={variant}
                          />
                        ) : null,
                      )}
                    </div>
                  </div>
                ) : null,
              )}
            </div>
          )}
          {showSubmitButton && (
            <Button
              {...buttonProps}
              className={twMerge('self-end', buttonProps?.className)}
              disabled={buttonProps?.disabled || isSubmitting}
              label={getButtonLabel()}
              onClick={() => '/'}
              submitButton
              type="primary"
            />
          )}
        </form>

        {isError && (
          <Presence id={`${id}-error-message-presence`} visible>
            <Alert
              className="break-words"
              iconSize="xl"
              onClose={onCloseAlert}
              title={errorTitle ?? t('errors.submitTitle') ?? ''}
              type="error"
            >
              {errorSubtitle ?? t('errors.submitSubtitle')}
            </Alert>
          </Presence>
        )}
      </Presence>
    );
  },
);

DynamicForm.displayName = 'DynamicForm'; // Added display name for the component

export default DynamicForm;
