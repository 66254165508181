import { faPause, faPlay } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import IconButton from '../../IconButton';
import Image from '../../Image';
import Video from '../../Video';
import { HeroProps } from '../index';

const HeroPrimaryWithvideo = ({
  title,
  image,
  imageAlt,
  backgroundColor,
  className,
  imageClassName,
  imageWrapperClassName,
  videoSrc,
}: HeroProps) => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const handleMouseMoves = (flip: boolean) => {
    if (videoPlaying) {
      setShowButton(flip);
    }
  };

  return (
    <div
      className={twMerge(
        'rounded-r-brand rounded-bl-brand relative flex min-h-[300px] w-full flex-row bg-gray-600 ',
        className,
      )}
      onMouseEnter={() => handleMouseMoves(true)}
      onMouseLeave={() => handleMouseMoves(false)}
      style={{ backgroundColor }}
    >
      <h1 className="h3 z-20 flex w-full shrink-0 items-center justify-center px-6 text-white md:w-2/5 md:w-3/5">
        {title}
      </h1>
      {image && (
        <div className="absolute inset-x-0 inset-y-0 z-0 h-[300px] md:relative md:w-2/5">
          {videoSrc && (
            <Video
              className="absolute inset-0 h-full"
              onEnded={() => {
                setVideoPlaying(false);
                setShowButton(true);
              }}
              playing={videoPlaying}
              showControls={false}
              src={videoSrc}
            />
          )}
          {image && !videoPlaying && (
            <Image
              alt={imageAlt}
              className={twMerge('rounded-r-brand absolute inset-0 z-10 h-full object-cover', imageClassName)}
              focalpoint="right"
              src={image}
              wrapperClassName={imageWrapperClassName}
            />
          )}
          {showButton && videoSrc && (
            <div
              className="absolute bottom-4 right-4 z-20 text-black"
              onClick={() => {
                setVideoPlaying(!videoPlaying);
              }}
            >
              <IconButton chip icon={videoPlaying ? faPause : faPlay} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HeroPrimaryWithvideo;
