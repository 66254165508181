import { useTranslation } from 'next-i18next';

import { Modal } from '@boss/ui';

import { useProfile } from '../../../hooks';
import { MagazineCardForm } from '../../Form';

const MagazineCardModal = ({
  onCloseModal: handleCloseModal,
  onSubmit: handleSubmit,
  isLoading,
}: {
  onCloseModal: (value: boolean) => void;
  onSubmit: (values: { email: string; firstName: string; lastName: string; subscribeNewsletter: boolean }) => void;
  isLoading: boolean;
}) => {
  const { isLoggedIn } = useProfile();
  const { t } = useTranslation('common', {
    keyPrefix: 'magazineCard.modal',
  });

  return (
    <Modal className="md:max-w-[500px]" onClose={() => handleCloseModal(false)}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <p className="text-lg font-medium">{t('title')}</p>
          {!isLoggedIn && <p className="text-sm text-gray-600">{t('description')}</p>}
        </div>

        <MagazineCardForm
          isSubmitting={isLoading}
          onSubmit={handleSubmit}
          submitButtonLabel={t('subscribe') as string}
        />
      </div>
    </Modal>
  );
};

export default MagazineCardModal;
