import { ReactNode } from 'react';

import HeroPrimary from './Primary';
import HeroPrimaryWithVideo from './PrimaryWithVideo';
import HeroSecondary from './Secondary';
import HeroSecondaryWithVideo from './SecondaryWithVideo';

type HeroVariant = 'primary' | 'secondary';

export interface HeroProps {
  backgroundColor?: string;
  className?: string;
  image?: string;
  imageAlt?: string;
  imageClassName?: string;
  imageWrapperClassName?: string;
  title: ReactNode;
  videoSrc?: string;
}

export interface Props extends HeroProps {
  variant: HeroVariant;
}

/**
 * Represents the properties of the HeroBanner
 * @property {string} backgroundColor - background color for when no image is provided, if not provided will use a default.
 * @property {string} className - an optional prop to add extra styling to the component
 * @property {string} image - the image used as background for the hero. if no image provided, backgroundColor wll be used.
 * @property {string} videoSrc - optional - provides a video-background.
 * @property {ReactNode} title - text of the hero component - required
 * @property {HeroVariant} variant - primary for b2c and secondary for corporate/b2b
 */

const Hero = (props: Props) => {
  const { variant, videoSrc } = props;

  if (variant === 'primary') {
    if (videoSrc) {
      return <HeroPrimaryWithVideo {...props} />;
    }
    return <HeroPrimary {...props} />;
  }

  if (variant === 'secondary') {
    if (videoSrc) {
      return <HeroSecondaryWithVideo {...props} />;
    }
    return <HeroSecondary {...props} />;
  }

  return null;
};

export default Hero;
