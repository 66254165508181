import { columns } from './FormHelpers';
import { useFormField } from '../../hooks';
import { buildFormFields } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

type Props = {
  onSubmit: (values: { email: string; firstName: string; lastName: string; subscribeNewsletter: boolean }) => void;
  submitButtonLabel?: string;
  isSubmitting: boolean;
};

const MagazineCardForm = ({ onSubmit: handleSubmit, submitButtonLabel, isSubmitting }: Props) => {
  const { firstname, lastname, email, newsletter, termsandconditions } = useFormField();

  const baseFields: FormField[] = [
    { ...firstname, colStyle: columns.FULL },
    { ...lastname, colStyle: columns.FULL },
    { ...email, initialValue: email.initialValue ?? '', colStyle: columns.FULL },
    { ...newsletter, colStyle: columns.FULL },
    { ...termsandconditions, colStyle: columns.FULL },
  ];

  const handleFormSubmit = (values: FormValues) => {
    handleSubmit({
      email: values.email.toString() ?? '',
      firstName: values.firstname.toString() ?? '',
      lastName: values.lastname.toString() ?? '',
      subscribeNewsletter: !!values.newsletter ?? false,
    });
  };

  return (
    <DynamicForm
      buttonProps={{
        className: 'w-full',
        label: submitButtonLabel ?? 'Verstuur',
      }}
      fields={buildFormFields(baseFields)}
      id="magazineCard"
      isSubmitting={isSubmitting}
      onSubmit={handleFormSubmit}
      variant="light"
    />
  );
};

export default MagazineCardForm;
